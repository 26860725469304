import React from "react";
import CourseClasses from "./CourseClasses";
import CourseEnrollmentActions from "./CourseEnrollmentActions";
import CourseEnrollmentReview from "./CourseEnrollmentReview";
import CourseHeader from "./CourseHeader";
import CourseSidebar from "./CourseSidebar";
import CourseLocations from "./CourseLocations";
import CourseSchedules from "./CourseSchedules";
import LocationSidebar from "../../../locations/components/locationDetails/LocationSidebar";
import { Navigation, PageURL } from "../../../../lib";
import { Helmet } from "react-helmet-async";

export default class CourseDetails extends React.PureComponent {
  state = {
    enrollmentSuccessMessage: "",
  };

  componentDidMount() {
    this.setCourseScheduleId();
    this.showSuccess();
  }

  setCourseScheduleId = () => {
    const {
      courseDetails: { courseScheduleEnrollment, courseSchedulesOffered },
      pageRoute: {
        page,
        params,
        query,
        query: { loc: locationId, sched: courseSchedId },
      },
    } = this.props;

    if (
      !locationId ||
      !courseSchedulesOffered ||
      !courseSchedulesOffered.length
    ) {
      //clear out sched query param if no location/schedules
      if (courseSchedId) {
        Navigation.redirect(
          PageURL.to(page, params, { ...query, sched: undefined }),
        );
      }
    } else {
      let enrolledCourseSchedule;
      if (courseScheduleEnrollment) {
        enrolledCourseSchedule = courseSchedulesOffered.find(
          (cs) => cs.id === courseScheduleEnrollment.eduCourseScheduleID,
        );
      }

      //if the student is not already enrolled in a schedule, only schedules that did not yet end are available for selection & in the schedules dropdown
      const availableCourseSchedulesOffered = courseSchedulesOffered.filter(
        (cs) => !cs.didClassesEnd,
      );

      let selectedCourseSchedule;
      if (courseSchedId) {
        selectedCourseSchedule = availableCourseSchedulesOffered.find(
          (cs) => cs.id.toString() === courseSchedId.toString(),
        );
      }

      const courseSchedule =
        enrolledCourseSchedule || //student is enrolled in a schedule for this course - view that one bec student cannot enroll in two schedules for the same course
        selectedCourseSchedule || //sched selected by query param
        availableCourseSchedulesOffered[0]; //no sched selected, default to first in list of available schedules which is ordered by ones starting soonest first

      if (
        !selectedCourseSchedule ||
        (courseSchedule && courseSchedule.id !== selectedCourseSchedule.id)
      ) {
        //reset sched query param to match enrolled/selected/first-avail schedule
        const { page, params, query } = this.props.pageRoute;
        Navigation.redirect(
          PageURL.to(page, params, {
            ...query,
            sched: courseSchedule ? courseSchedule.id : undefined,
          }),
        );
      }
    }
  };

  getStudentEnrollmentAvailability = (
    courseSchedule,
    newEnrollment = false,
  ) => {
    const {
      courseDetails: { courseLocation, courseScheduleEnrollment },
      myLocation,
      user,
    } = this.props;

    const isBlockedByCourseSchedule = !courseSchedule; //no course schedule
    const isBlockedByStudentEnrollment =
      newEnrollment && courseScheduleEnrollment; //already enrolled in course sched
    const isBlockedByStudentLocation = //course sched location does not match user location
      user &&
      (!myLocation || !courseLocation || myLocation.id !== courseLocation.id);

    return {
      isEnrollmentAvailable:
        !isBlockedByCourseSchedule &&
        !isBlockedByStudentEnrollment &&
        !isBlockedByStudentLocation,
      isBlockedByCourseSchedule,
      isBlockedByStudentEnrollment,
      isBlockedByStudentLocation,
    };
  };

  showEnrollmentReview = (courseSchedule) => {
    const {
      pageRoute: {
        query: { enroll },
      },
      myJewishUStatus,
    } = this.props;

    return (
      !!enroll &&
      myJewishUStatus !== "Rejected" &&
      this.getStudentEnrollmentAvailability(courseSchedule, true)
        .isEnrollmentAvailable //is new enrollment available
    );
  };

  showSuccess = () => {
    const { success } = this.props.courseEnrollment;
    if (success) {
      this.setState({
        enrollmentSuccessMessage:
          "Thank you, your application for this course is pending.",
      });
    }
  };

  onConfirmEnrollment = async (courseScheduleId) => {
    await this.props.onConfirmCourseEnrollment(courseScheduleId);
    this.showSuccess();
  };

  render() {
    const {
      courseDetails,
      courseEnrollment,
      courseEnrollmentCancellation,
      courseEnrollmentUpdate,
      myJewishUStatus,
      myLocation,
      onCancelCourseEnrollment,
      onEnrollInCourse,
      onRefreshCourseDetails,
      pageRoute,
    } = this.props;

    const { enrollmentSuccessMessage } = this.state;

    const {
      query: { sched: courseSchedId },
    } = pageRoute;
    const {
      chabadHousesOffering,
      courseLocation,
      courseScheduleEnrollment,
      courseSchedulesOffered,
      classes,
      description,
      imageURL,
    } = courseDetails;

    const courseSchedule =
      courseSchedId &&
      courseSchedulesOffered.find(
        (cs) => cs.id.toString() === courseSchedId.toString(),
      );

    const isStudentEnrolledInCourse = !!courseScheduleEnrollment;
    const isStudentEnrolledInCourseSchedule =
      courseSchedule &&
      courseScheduleEnrollment &&
      courseScheduleEnrollment.eduCourseScheduleID === courseSchedule.id;

    const studentEnrollmentAvailability =
      this.getStudentEnrollmentAvailability(courseSchedule);

    const showEnrollmentReview = this.showEnrollmentReview(courseSchedule);

    return (
      <React.Fragment>
        <Helmet prioritizeSeoTags>
          {imageURL && <meta property="og:image" content={imageURL} />}
        </Helmet>
        <CourseHeader courseDetails={courseDetails} />
        <div className="container mb-80">
          {enrollmentSuccessMessage && (
            <p
              className="xxl-text mb-40 fw-600 flex flex-align-center"
              style={{ color: "#63C674" }}
            >
              <span
                className="material-icons mr-8"
                style={{ fontSize: "28px" }}
              >
                check_circle_outline
              </span>
              {enrollmentSuccessMessage}
            </p>
          )}
          <div className="flex mobile-block">
            <div className="course-details-sidebar">
              {courseLocation && (
                <div className="course-details-sidebar-location-info mb-40">
                  <LocationSidebar location={courseLocation} />
                </div>
              )}

              <CourseSidebar courseDetails={courseDetails} />
              {!showEnrollmentReview && (
                <CourseEnrollmentActions
                  courseEnrollmentCancellation={courseEnrollmentCancellation}
                  courseEnrollmentUpdate={courseEnrollmentUpdate}
                  courseSchedule={courseSchedule}
                  courseScheduleEnrollment={courseScheduleEnrollment}
                  isStudentEnrolledInCourse={isStudentEnrolledInCourse}
                  isStudentEnrolledInCourseSchedule={
                    isStudentEnrolledInCourseSchedule
                  }
                  myJewishUStatus={myJewishUStatus}
                  myLocation={myLocation}
                  onCancelCourseEnrollment={() => {
                    this.setState({ enrollmentSuccessMessage: "" });
                    onCancelCourseEnrollment(courseScheduleEnrollment.id);
                  }}
                  onRefreshCourseDetails={onRefreshCourseDetails}
                  onEnrollInCourse={onEnrollInCourse}
                  pageRoute={pageRoute}
                  studentEnrollmentAvailability={studentEnrollmentAvailability}
                />
              )}
            </div>
            <div className="desktop-hidden tablet-hidden mobile-course-details-divider"></div>
            <div className="full-width">
              {showEnrollmentReview ? (
                <CourseEnrollmentReview
                  courseClasses={classes}
                  courseEnrollment={courseEnrollment}
                  courseSchedule={courseSchedule}
                  confirmEnrollment={this.onConfirmEnrollment}
                />
              ) : (
                <div>
                  {!isStudentEnrolledInCourseSchedule && description && (
                    <div className="course-details-section mb-80">
                      <p className="course-details-section-title">
                        About this course
                      </p>
                      <p
                        className="line-height-double medium-text"
                        dir="auto"
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {description}
                      </p>
                    </div>
                  )}

                  {!courseLocation ? (
                    <CourseLocations
                      locations={chabadHousesOffering}
                      myLocation={myLocation}
                      pageRoute={pageRoute}
                    />
                  ) : (
                    !isStudentEnrolledInCourseSchedule &&
                    courseSchedulesOffered && (
                      <CourseSchedules
                        courseLocationName={courseLocation.chabadHouseName}
                        courseScheduleId={
                          courseSchedId ? parseInt(courseSchedId) : ""
                        }
                        courseSchedules={courseSchedulesOffered.filter(
                          (cs) => !cs.didClassesEnd,
                        )}
                        pageRoute={pageRoute}
                      />
                    )
                  )}

                  <CourseClasses
                    classes={classes}
                    courseSchedule={courseSchedule}
                    title={
                      isStudentEnrolledInCourseSchedule
                        ? `${courseSchedule.name} Course Schedule`
                        : "Course Syllabus"
                    }
                    isStudentEnrolledInCourseSchedule={
                      isStudentEnrolledInCourseSchedule
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
