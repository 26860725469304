import React from "react";
import CourseDetails from "./components/courseDetails/CourseDetails";
import ErrorDisplay from "../../components/ErrorDisplay";
import Loader from "../../components/Loader";
import withLayout from "../../components/layout/withLayout";
import { withAppInsights } from "../../lib/AppInsights";
import { Navigation, PageURL } from "../../lib";
import Pages from "../../pages";

class CourseDetailsPage extends React.PureComponent {
  state = {
    didInitializeCourseDetails: false, //when switching between my courses in nav dropdown menu, courseDetails remounts before course data is reset, triggering a reset of the new course sched query param to match a sched for the previous course, t/f need this initialization state.
  };

  componentDidMount() {
    // when accessing a 'course review' link: if the user is not logged in, redirect to the login page
    const {
      pageRoute: { location, query },
      user,
    } = this.props;
    if (!user && query.review) {
      Navigation.redirect(
        PageURL.to(Pages.account.login.path, null, {
          after: location.pathname + location.search,
        }),
      );
      return;
    }

    this.getCourseDetails();
  }

  componentWillUnmount() {
    this.props.actions.resetCourse();
  }

  componentDidUpdate(prevProps) {
    const {
      pageRoute: {
        params: { courseId },
        query: { loc },
      },
      user,
    } = this.props;
    const {
      pageRoute: {
        params: { courseId: prevCourseId },
        query: { loc: prevLoc },
      },
      user: prevUser,
    } = prevProps;
    if (
      courseId !== prevCourseId ||
      loc !== prevLoc ||
      user?.id !== prevUser?.id
    ) {
      this.getCourseDetails();
    }
  }

  getCourseDetails = async (isRefresh = false) => {
    const {
      pageRoute: {
        params: { courseId },
        query: { loc: locationId },
      },
    } = this.props;

    await this.props.actions.getCourse(courseId, locationId, isRefresh);

    if (!this.state.didInitializeCourseDetails) {
      this.setState({ didInitializeCourseDetails: true });
    }
  };

  onCancelCourseEnrollment = async (courseScheduleEnrollmentId) => {
    await this.props.actions.doCourseEnrollmentCancellation(
      courseScheduleEnrollmentId,
      () => this.getCourseDetails(true),
    );
  };

  onConfirmCourseEnrollment = async (courseScheduleId) => {
    await this.props.actions.doCourseEnrollment(courseScheduleId, () =>
      this.getCourseDetails(true),
    );

    if (this.props.courseEnrollment.success) {
      this.toggleEnrollURLParam();
    }
  };

  onEnrollInCourse = () => {
    this.toggleEnrollURLParam(true);
  };

  toggleEnrollURLParam = (enroll) => {
    const {
      pageRoute: { query, page, params },
      user,
    } = this.props;
    const enrollUrl = PageURL.to(page, params, {
      ...query,
      enroll,
    });

    if (!user) {
      Navigation.redirect(
        PageURL.to(Pages.account.login.path, params, {
          ...query,
          after: enrollUrl,
        }),
      );
    } else {
      Navigation.redirect(enrollUrl);
    }
  };

  render() {
    const {
      courseEnrollment,
      courseEnrollmentCancellation,
      courseEnrollmentUpdate,
      data: courseDetails,
      error,
      loading,
      myJewishU: {
        data: {
          eduChabadHouse: myLocation,
          eduStudentEnrollmentStatus: myJewishUStatus,
        } = {},
      },
      pageRoute,
      user,
    } = this.props;

    const { didInitializeCourseDetails } = this.state;

    return (
      <div className="page course-details-page">
        {loading ? (
          <Loader className="full-page-loader" />
        ) : error ? (
          <ErrorDisplay />
        ) : (
          !!(courseDetails && courseDetails.id) &&
          didInitializeCourseDetails && (
            <CourseDetails
              courseDetails={courseDetails}
              courseEnrollment={courseEnrollment}
              courseEnrollmentCancellation={courseEnrollmentCancellation}
              courseEnrollmentUpdate={courseEnrollmentUpdate}
              myJewishUStatus={myJewishUStatus}
              myLocation={myLocation}
              onCancelCourseEnrollment={this.onCancelCourseEnrollment}
              onConfirmCourseEnrollment={this.onConfirmCourseEnrollment}
              onEnrollInCourse={this.onEnrollInCourse}
              onRefreshCourseDetails={() => this.getCourseDetails(true)}
              pageRoute={pageRoute}
              user={user}
            />
          )
        )}
      </div>
    );
  }
}

export default withLayout({
  // getTitle: ({ data: courseDetails }) => courseDetails && courseDetails.name,
  goBack: true,
})(withAppInsights(CourseDetailsPage));
